export default {
  loginBegin(state) {
    state.loading = true;
  },
  loginSuccess(state, data,) {
    state.loading = false;
    state.login = data.login;
    state.super_login=data.super_login;
  },
  
  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  logoutBegin(state) {
    state.loading = true;
  },
  
  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
    state.super_login=false;
  },
  
  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  
  menuData(state,data)
  {
    state.menuData = data;
  },
  
  websiteMenuData(state,data)
  {
    state.website_menu_data = data;
  },
  someMutation(state, lang) {  
    state.site_lang = lang;  
  }, 
  setCollapsed(state, payload) {
    state.collapsed = payload;
    
  },
  superToken(state, payload) {
    state.super_token = payload;
  },
};
